import React from 'react'
import {Tabs, Tab} from 'react-bootstrap-tabs';

import ChangeLog from './ChangeLog/ChangeLog';
import InProgress from './In Progress/InProgress';
import Backlog from './Backlog/Backlog';

function TabLayout() {
    return(
        <Tabs style={{width: '100%'}} activeHeaderStyle={{color: 'black', fontWeight: 'bold'}}>
            <Tab label="changeLog">
                <ChangeLog />
            </Tab>
            <Tab label="inProgress">
                <InProgress />
            </Tab>
            <Tab label="backlog">
                <Backlog />
            </Tab>
        </Tabs>
    )
}

export default TabLayout;

