import React from 'react';

import MusiciansAideImg from '../../images/croppedScreenshot.png';
import MusiciansAideReflect from '../../images/musiciansAideReflect.png';

import AppMarketing from '../Project_template/App-marketing';

import ReactGA from 'react-ga';

class MusiciansAide extends React.Component {
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        return(
            <AppMarketing
                appName="Musicians Aide"
                title="Musicians Aide Metronome | Android Music Practice Tool"
                description="Our metronome will help you be more efficient in the practice room. The app allows you to input any compound or complex meter, and computes an example beat pattern"
                keywords="Musicians Aide, Metronome, Android, Programmable, Low Latency, High Performance, meters, complex, compound"
                mediaUrl={MusiciansAideReflect}
                mediaUrlMobile={MusiciansAideImg}
                imgAlt="Musicians Aide"
                subHeading="Making practicing more efficient.  Made by musicians, for musicians."
                aboutHeading="About the project"
                about={
                    <div>
                        <p>This project was originally inspired by the tried and true Dr. Beat metronome.  
                            This is a very robust metronome that can literally do anything you throw at it.
                            However, a colleague of mine expressed how repetitive and time consuming the
                            process of programming it is.
                        </p>

                        <p>
                            Thus the project <i>Musicians Aide</i> was born!  The goal right out of the gate is to
                            make this metronome the most efficient practice tool on the market!  This starts right
                            down to the first version.  Not only will version 1 be completely free (plus Ad free),
                            it hopefully will also rival other paid apps out on the market right now!
                        </p>

                        <p>
                            The next goal is to try to capture the essence of the Dr. Beat into a simpler and easy to 
                            use metronome app.  We will tackle the challenge of making complete programmability in as little 
                            user actions as possible, and including enough features to double your productivity in the practice room!
                        </p>
                    </div>
                }
                featuresName="Features"
                features={
                    <ul>
                        <li><strong>Complete control</strong> over Accents</li>
                        <ul>
                            <li>Choose between 4 different instruments</li>
                            <li>8th, triplet, and 16th note sub-divisions</li>
                        </ul>
                        <li>Configure <strong>any time signature!</strong></li>
                        <ul>
                            <li>Takes any number of beats per measure, for your odd meters</li>
                            <li>Configure a beat value from a whole note to a 32nd note!</li>
                            <li>You can even change the tempo marking! (ex: dotted quarter = tempo)</li>
                        </ul>
                        <li><strong>Quick save</strong> buttons to store your most common tempos</li>
                        <li><strong>Continuous playback</strong> even when changing the settings</li>
                    </ul>
                }
                futurePlansName="Looking into the Future"
                futurePlans={
                    <div>
                        <p>We want musicians to take their sessions to the next level
                            and double their efficiency through programmability. 
                            No need to stop and reset your metronome on meter changes, this will be an issue of the past!
                        </p>

                        <h4>Some features to expect in Ver 2:</h4>
                        <ul>
                            <li>Program your entire piece or solo into the app</li>
                            <li>Simple, easy, efficient input menus</li>
                            <li>Rehearse specific sections, or the entire piece</li>
                            <li>Share your programs on the cloud for re-use!</li>
                            <li>Accelerandos, Ritardandos, what else can we add?</li>
                        </ul>
                    </div>
                }
                betaTestingHeader="Available now on the Google Play Store!"
            >

            </AppMarketing>

        );
    }
    
}

export default MusiciansAide;