import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { tsConstructorType } from '@babel/types';
import axios from 'axios';

import './ContactForm.scss';

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.email = React.createRef();
        this.selectItem = React.createRef();
        this.emailBody = React.createRef();
        this.state = {
            validated: false,
        }
    }

    handleSubmit = event => {
        const form = event.currentTarget;
            event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
        }

        this.setState({validated: true});
        
        console.log("Email: " + this.email.current.value);
        console.log("Type: " + this.selectItem.current.value);
        console.log("Body: " + this.emailBody.value);

        axios.post('/send-email', {
            email: this.email.current.value,
            subject: this.selectItem.current.value,
            text: this.emailBody.value
        }).then((res) => {
            if (res.data.msg === 'success') {
                alert("Message sent.");
                this.resetForm();
            } else if (res.data.msg === 'fail') {
                alert("Message failed to send.");
            }
            console.log('Response: ', res);
        })
    }

    resetForm() {
        document.getElementById('contact-form').reset();
    }

    render() {
        return(
            <Form id="contact-form" validated={this.state.validated} onSubmit={this.handleSubmit.bind(this)} method="POST">
                <Form.Group controlId="formEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control 
                        type="email" 
                        placeholder="Enter email" 
                        ref={this.email} 
                        required
                        pattern="\S+@\S+\.\S+"/>
                    <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>
    
                <Form.Group controlId="formsSubjectSelect">
                    <Form.Label>Email Subject Heading</Form.Label>
                    <Form.Control 
                        as="select" 
                        required 
                        defaultValue=""
                        ref={this.selectItem}>

                        <option value="" disabled>Select the correspondence</option>
                        <option value="General Inquiries">General Inquiries</option>
                        <option value="Recruiting Inquiries">Recruiting Inquiries</option>
                        <option value="Musicians Aide - suggestions">Musicians Aide - suggestions</option>
                        <option value="Muicians Aide - Issues">Muicians Aide - Issues</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="formEmailBody">
                    <Form.Label>Email Body</Form.Label>
                    <Form.Control 
                        as="textarea" 
                        required  
                        placeholder="Email Body"
                        ref={(emailBody) => {this.emailBody = emailBody}}
                        minLength="20"
                    />
                </Form.Group>
                <Button variant="primary" type="submit">
                    Send
                </Button>
            </Form>
        );
    }
}

export default ContactForm;