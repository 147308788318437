import React from 'react';
import { Media } from '../Breakpoints/Media';
import { Col, Row } from 'react-bootstrap';

import Contact from '../ContactForm/ContactForm';
import FBicon from '../../icons/f_logo_RGB-Blue_58.png';
import IGicon from '../../icons/IG_logo.png';

import './footer.scss';

function Footer() {
    return(
        <div className="footer">
            <Media lessThan="lg">
                <h4>Contact Us</h4>
                
                <Contact />

                <h4 className="pad-top">Follow us for news and updates</h4>

                <a href="https://www.facebook.com/Astar-App-Foundry-102537818084174/" className="social fb">
                    <img src={FBicon} alt="Facebook logo" />
                </a>

                <a href="https://www.instagram.com/astarappfoundry/" className="social">
                    <img src={IGicon} alt="Instagram Logo" />
                </a>

            </Media>
            <Media greaterThanOrEqual="lg">
                <Row>
                    <Col lg={6}>
                        <h4>AStar App Foundry, LLC</h4>
                        <h4 className="pad-top">Follow us for news and updates</h4>

                        <a href="https://www.facebook.com/astarappfoundry/" className="social fb">
                            <img src={FBicon} alt="Facebook logo" />
                        </a>

                        <a href="https://www.instagram.com/astarappfoundry/" className="social">
                            <img src={IGicon} alt="Instagram Logo" />
                        </a>
                    </Col>
                    <Col lg={6}>
                        <h4>Contact Us</h4>
                        
                        <Contact />
                    </Col>
                </Row>

            </Media>
        </div>
    );
}

export default Footer;