import React from 'react';

import './Text.scss';

class Text extends React.Component {
    render() {
        const smallPadding = this.props.smallPadding;

        return(
            <div className={`textContent ${smallPadding ? "smallPadding" : ""}`}>
                {this.props.children}
            </div>
        )
    }
}

export default Text;