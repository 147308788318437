import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import './AppRouting.scss';

import About from '../../pages/About_Me/About';
import Home from '../../pages/Home/Home';
import MusiciansAide from '../../pages/MusiciansAide/MusiciansAide';
import PageNotFound from '../../pages/404/404'

function AppRouting() {
    return(
        <Container className="body-padding">
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/MusiciansAide" component={MusiciansAide} />
                <Route path="/About" component={About} />
                <Route component={PageNotFound} />
            </Switch>
        </Container>
    );
}

export default AppRouting;