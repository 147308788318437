import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Text from '../../components/ContentSection/Text/Text'
import Image from '../../components/ContentSection/Image/Image';

import './App-marketing.scss';

import ScrollToTopOnMount from '../../components/Navigation/ScrollToTopOnMount';
import {Helmet} from "react-helmet";
import { Media } from "../../components/Breakpoints/Media"
import TabLayout from "../../components/TabLayout/TabLayout"

import ReactGA from 'react-ga';

class AppMarketing extends React.Component {
    render() {
        const mediaSrc = this.props.mediaUrl;
        const mediaSrcMobile = this.props.mediaUrlMobile;
        const imgAlt = this.props.imgAlt;

        const title = this.props.title;
        const appName = this.props.appName;
        const subHeading = this.props.subHeading;
        const aboutHeading = this.props.aboutHeading;
        const about = this.props.about;
        const description = this.props.description;
        const keywords = this.props.keywords;

        const featuresName = this.props.featuresName;
        const features = this.props.features;
        const futurePlansName = this.props.futurePlansName;
        const futurePlans = this.props.futurePlans;
        const betaTestingHeader = this.props.betaTestingHeader;
        const betaTestSubHeader = this.props.betaTestSubHeader;
        const betaTesting = this.props.betaTesting;

        let isClient = false;

        if (typeof(window) !== 'undefined') {
            isClient = true;
        }

        const handleClick = () => {
            if (isClient) {
                ReactGA.event({
                    category: "Download APK",
                    action: "User Downloaded the app"
                });
            }
        }

        return (
            <div className="AppMarketing">

                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description}/>
                    <meta name="robots" content="index, follow"/>
                    <meta name="keywords" content={keywords}/>
                </Helmet>

                <ScrollToTopOnMount />

                <div className="marketing-content no-gutter">
                    <Row>
                        {isClient ? (
                            this.header(appName, subHeading, mediaSrcMobile, imgAlt, isClient)
                        ) : null }
                        
                    </Row>
                    <Row className="content-container">
                        <Col lg={8}>
                            <Row className="content-row bg2">
                                <Text>
                                    <div className="content-header">
                                        <h1>{featuresName}</h1>
                                    </div>

                                    {features}
                                </Text>
                            </Row>
                            <Row className="content-row">
                                <Text>
                                    <div className="content-header">
                                        <h1>{futurePlansName}</h1>
                                    </div>

                                    {futurePlans}
                                </Text>
                            </Row>
                        </Col>
                        {isClient ? (
                            <Col className="d-none d-lg-block" lg={4}>
                                <Image imgUrl={mediaSrc} imgAlt={imgAlt}/>
                            </Col>
                        ) : null }
                    </Row>
                    <Row className="content-container">
                        <Col>
                            <Row className="content-row bg2">
                                <Text>
                                    <div className="content-header">
                                        <h1>{aboutHeading}</h1>
                                    </div>
                                    
                                    {about}
                                </Text>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="content-container">
                        <Col>
                            <Row className="content-row">
                                <Text>
                                    <div className="content-header">
                                        <h1>{betaTestingHeader}</h1>
                                        <h4>{betaTestSubHeader}</h4>
                                    </div>

                                    {betaTesting}
                                </Text>
                            </Row>
                            <Row className="content-row">
                                <Col className="googleLink noPadding" xs={12} md={6}>
                                    <Media className="smaller" greaterThanOrEqual="lg">
                                        <a onClick={isClient ? handleClick : null} href='https://play.google.com/store/apps/details?id=com.musiciansAide.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                            <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                                        </a>
                                    </Media>

                                    <Media className="smaller mobile" lessThan="lg">
                                        <a onClick={isClient ? handleClick : null} href='https://play.google.com/store/apps/details?id=com.musiciansAide.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                            <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                                        </a>
                                    </Media>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className="center">
                                        <h4>Help support development</h4>

                                        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                                            <input type="hidden" name="cmd" value="_donations" />
                                            <input type="hidden" name="business" value="H9B4HCSU3RBM4" />
                                            <input type="hidden" name="item_name" value="Support App Development" />
                                            <input type="hidden" name="currency_code" value="USD" />
                                            <input type="image" className="leftAlign" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                                            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                                        </form>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="content-container">
                        <Col>
                            <Row className="content-row bg2 tabs-padding">
                                <TabLayout/>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

    header(appName, subHeading, mediaSrcMobile, imgAlt, isClient) {
        return(
                isClient ? (
                    <Col>
                        <Row className="content-row flex">
                            <Col lg={3} className="googleLink">
                                <Media className="default" greaterThanOrEqual="lg">
                                    <a href='https://play.google.com/store/apps/details?id=com.musiciansAide.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                        <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                                    </a>
                                </Media>
                            </Col>

                            <Col xs={12} lg={6}>
                                <Media greaterThanOrEqual="lg">
                                    <div className="heading">
                                        <h1>{appName}</h1>
                                        <h5>{subHeading}</h5>
                                    </div>
                                </Media>

                                <Media lessThan="lg">
                                    <div className="heading mobile">
                                        <h1>{appName}</h1>
                                        <h5>{subHeading}</h5>
                                    </div>
                                </Media>
                            </Col>

                            <Media greaterThanOrEqual="lg">
                                <Col lg={3}></Col>
                            </Media>
                        </Row>
                        <Row>
                            <Col lg={12} className="googleLink noPadding">
                                <Media lessThan="lg">
                                    <a href='https://play.google.com/store/apps/details?id=com.musiciansAide.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                        <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                                    </a>

                                    <Image noBotPadding={true} imgUrl={mediaSrcMobile} imgAlt={imgAlt}/>
                                </Media>
                            </Col>
                        </Row>
                    </Col>
                ) : null
        )
    }
}

export default AppMarketing;