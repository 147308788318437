import React from 'react';
import './404.scss';

function PageNotFound(props) {
    return (
        <div className="notFound">
            <div>
                <h1>404: Page Not Found</h1>
                <p>Despite our hamsters best efforts, nothing was found regarding your requested path.</p>
            </div>
            
        </div>
    )
}

export default PageNotFound;