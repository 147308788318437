import React from 'react';
import ReactMarkdown from 'react-markdown';
import {Row, Col} from 'react-bootstrap';
import Text from '../../ContentSection/Text/Text'

class InProgress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firebase: props.firebase,
            tasks: []
        }
    }

    componentDidMount() {
        this.inProgressRef = firebase.database().ref('flamelink/environments/production/content/inProgress/en-US').orderByChild("task/scheduledRelease");
        this.inProgressRef.once('value', (snapshot) => {
            snapshot.forEach(item => {
                this.setState({tasks: this.state.tasks.concat(item.val())});
            });
        });
    }

    componentWillUnmount() {
        this.inProgressRef.off();
    }

    render() {
        var items = [];

        if (this.state.tasks.length > 0) {
            for (var key in Object.keys(this.state.tasks)) {
                var taskType = this.state.tasks[key].task.taskType;

                items.push(
                    <div className="content-card" key={this.state.tasks[key].id}>
                        <Row>
                            <Col xs={12} lg={8}><Text smallPadding="true"><h4>{this.state.tasks[key].task.taskName}</h4></Text></Col>
                            <Col xs={6} lg={2}><Text smallPadding="true"><h4>{this.state.tasks[key].task.scheduledRelease}</h4></Text></Col>
                            <Col xs={6} lg={2}>
                                <div className={`taskType ${taskType}`}>
                                    <Text smallPadding="true"><h4>{taskType}</h4></Text>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Text><ReactMarkdown>{this.state.tasks[key].task.taskNotes}</ReactMarkdown></Text>
                            </Col>
                        </Row>
                    </div>
                );
            }
        }

        return (
            <div className="content-container">
                {(items.length > 0) ? (
                    items
                ) : null}
            </div>
        )
    }
}

export default InProgress;