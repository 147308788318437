import React, { useState } from 'react';

import menuWhite from '../../icons/menu-white.svg';
import menuBlack from '../../icons/menu-black.svg';
import MobileMenu from './Mobile-menu/Mobile-menu';

import { Container, Row } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import throttle from 'lodash.throttle';
import { CSSTransition } from 'react-transition-group';

import './Navigation.scss';

class Navigation extends React.Component {
    constructor() {
        super();

        this.state = {
            isMobileMenuOpen: false,
            active: false,
            isTransparent: true,
        }

        this.showMenu = this.showMenu.bind(this);
        this.showDropdown = this.showDropdown.bind(this);
        this.closeDropdown = this.closeDropdown.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.throttleScroll = throttle(this.handleScroll, 200);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.throttleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.throttleScroll);
    }

    // When the Menu icon is clicked, the 'listener' method of our MobileMenu component is called.
    showMenu(event) {
        event.preventDefault();
        this.child.showMenu();
    }

    showDropdown(event) {
        this.setState({ active: true }, () => {
            document.addEventListener('click', this.closeDropdown);
            window.addEventListener('scroll', this.closeDropdown);
        });
    }

    // Once an 'outside' click is detected, it closes the menu (toggles state), then removes the listener.
    closeDropdown(event) {
        this.setState({ active: false }, () => {
            document.removeEventListener('click', this.closeDropdown);
            window.removeEventListener('scroll', this.closeDropdown);
        });
    }

    handleScroll() {
        const headerScrollHeight = 1;


        if ((window.scrollY < headerScrollHeight) && !this.state.isTransparent) {
            this.setState({isTransparent: true});
        } else if ((window.scrollY > headerScrollHeight) && this.state.isTransparent) {
            this.setState({isTransparent: false});
        }
    }

    render() {
        const duration = 100;

        return(
            <div>
                <CSSTransition
                    in={!this.state.isTransparent}
                    timeout={duration}
                    classNames="opaque">
                    
                    <div className="App-navigation">
                        <Container fluid className="fill-height">
                            <Row className="fill-height center">
                                <nav className="Navbar fill-height">
                                    <Link to="/" 
                                        className={"Nav-item Nav-brand fill-height" 
                                            + (this.state.isTransparent ? '' : ' opaque')
                                        }>Home
                                    </Link>

                                    <Link to="/MusiciansAide" 
                                        className={"Nav-item Nav-link Nav-desktop fill-height" 
                                            + (this.state.isTransparent ? '' : ' opaque')
                                        }>Musicians Aide
                                    </Link>
                                    
                                    <Link to="/About" 
                                        className={"Nav-item Nav-link Nav-desktop fill-height" 
                                            + (this.state.isTransparent ? '' : ' opaque')
                                        }>About Us
                                    </Link>

                                    <div className="Nav-icon Nav-mobile fill-height" onClick={this.showMenu}>
                                        <img 
                                            className={(this.state.isTransparent ? '' : ' opaque')} 
                                            src={this.state.isTransparent ? menuWhite : menuBlack} 
                                            alt="Hamburger Icon"
                                        />
                                    </div>
                                </nav>
                            </Row>
                        </Container>
                    </div>
                    
                </CSSTransition>

                <MobileMenu 
                    onRef={ref => (this.child = ref)} 
                    showMenu={this.isMobileMenuOpen} 
                    opaque={!this.state.isTransparent}
                />
            </div>
        );
    }
}

export default withRouter(Navigation);