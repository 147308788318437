import React from 'react';
import ContentSection from '../../components/ContentSection/ContentSection';

import croppedScreenshot from '../../images/croppedScreenshot.png';
import './Home.scss';
import ScrollToTopOnMount from '../../components/Navigation/ScrollToTopOnMount';
import { Helmet } from 'react-helmet';

import ReactGA from 'react-ga';

class Home extends React.Component {
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        return(
            <div className="App-home">
                <Helmet>
                    <title>AStar App Foundry</title>
                    <meta name="description" content="Software, app, and web development.  Building the next crazy idea."/>
                    <meta name="robots" content="index, follow"/>
                </Helmet>
    
                <ScrollToTopOnMount />
    
                <div className="App-header">
                    <div className="heading">
                        <h1 id="headerTitle">AStar App Foundry</h1>
    
                        <h4 className="subHeading">Software and mobile app development</h4>
                    </div>
                </div>
    
                <div className="heading">
                    <h4 className="subHeading">We take radical, passionate ideas and see them to fruition</h4>
                    <h4 className="subHeading top-pad">Here's what we're working on:</h4>
                </div>
    
                <ContentSection 
                    flipped="true"
                    imgUrl={croppedScreenshot}
                    imgAlt="Musicians Aide"
                    imgNoBotPadding={true}
                    heading="Musicians Aide"
                    heading2="Android App"
                    content={
                        <ul>
                            <li>Simple user interfaces for more efficient practicing</li>
                            <li>Revolutionary meter and time adjustment</li>
                            <li>Save your favorite tempos, and much more planned!</li>
                        </ul>
                    }
                    route="/MusiciansAide"
                
                ></ContentSection>
    
            </div>
        );
    }
}

export default Home;