import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import MattProfilePic from '../../images/matt.jpg';
import BlankPhoto from '../../images/blankPhoto.jpg';

import './About.scss';
import Text from '../../components/ContentSection/Text/Text';
import Image from '../../components/ContentSection/Image/Image';

import ScrollToTopOnMount from '../../components/Navigation/ScrollToTopOnMount';
import { Helmet } from "react-helmet";

import ReactGA from 'react-ga';

class About extends React.Component {
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        return(
            <div className="About">
    
                <Helmet>
                    <title>Matthew Strom - About Me</title>
                    <meta name="description" content="A quick biography about myself and my passions"/>
                    <meta name="robots" content="index, follow"/>
                </Helmet>
    
                <ScrollToTopOnMount />
    
                <div className="About-header heading-photo">
    
                    <div className="heading">
                        <h1>About Us</h1>
                    </div>
                </div>
    
                <div className="About-content">
                    <Container>
                        <Row>
                            <Col xs={12} md={6}>
                                <Row>
                                    <Col className="heading" xs={12}>
                                        <h4 className="subHeading heading-padding">Matthew Strom</h4>
                                        <p className="heading-side-padding">Founder</p>
                                        <Image imgUrl={MattProfilePic} imgAlt="Matt's Picture"  noPadding="true" customStyles="customStyles"/>
                                    </Col>
                                    <Col className="content bg1" xs={12}>
                                        <Text smallPadding="true">
                                            I am a Software Developer who hails from the midwest and is currently
                                            experiencing all that Seattle has to offer.  I started my
                                            software journey at the University of Wisconsin - Eau Claire, where I was
                                            able to get some fantastic learning experiences from top tier teachers and 
                                            industry leaders.  Since then I have spent a couple years working on website
                                            applications in the industry and am currently developing Musicians Aide.
                                        </Text>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={12} md={6}>
                                <Row>
                                    <Col className="heading" xs={12}>
                                        <h4 className="subHeading heading-padding">Developers wanted!</h4>
                                        <p className="heading-side-padding">Developer</p>
                                        <Image imgUrl={BlankPhoto} imgAlt="photo unavailable"  noPadding="true" customStyles="customStyles"/>
                                    </Col>
                                    <Col className="bg1" xs={12}>
                                        <Text smallPadding="true">
                                            This could be you!  Interested in working on cool projects with me?  I'm looking out for enthuziastic developers experienced in Java, and C++, C#/Swift
                                        </Text>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

export default About;